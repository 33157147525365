import moment from 'moment';

require('initializers/gettext').default(__TRANSLATIONS__.gettext['en-GB']); // eslint-disable-line no-undef
require('initializers/i18n').default('en-GB', __TRANSLATIONS__.i18n['en-GB']); // eslint-disable-line no-undef

require('locales/dependencies');

require('jquery-ui/ui/i18n/datepicker-en-GB');
require('moment/locale/en-gb');

moment.updateLocale('en-gb', {
  months:
    'January_February_March_April_May_June_July_August_September_October_November_December'.split(
      '_',
    ),
  monthsShort: 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
  weekdays: 'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday'.split(
    '_',
  ),
  weekdaysShort: 'Sun_Mon_Tue_Wed_Thu_Fri_Sat'.split('_'),
  weekdaysMin: 'Su_Mo_Tu_We_Th_Fr_Sa'.split('_'),
  calendar: {
    sameDay: '[Today at] LT',
    nextDay: '[Tomorrow at] LT',
    nextWeek: 'dddd [at] LT',
    lastDay: '[Yesterday at] LT',
    lastWeek: '[Last] dddd [at] LT',
    sameElse: 'L',
  },
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    ss: '%d seconds',
    m: 'a minute',
    mm: '%d minutes',
    h: 'an hour',
    hh: '%d hours',
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
  dayOfMonthOrdinalParse: /\d{1,2}(st|nd|rd|th)/,
  ordinal: (number) => {
    const b = number % 10;
    const output =
      ~~((number % 100) / 10) === 1 // eslint-disable-line no-bitwise
        ? 'th'
        : b === 1
          ? 'st'
          : b === 2
            ? 'nd'
            : b === 3
              ? 'rd'
              : 'th';

    return number + output;
  },
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // The week that contains Jan 4th is the first week of the year.
  },
});

require('select2/dist/js/i18n/en');
require('dayjs/locale/en-gb');
